.react-date-picker__inputGroup__leadingZero {
  position: relative;
  top: 0px;
}

.MuiSlider-root {
  .MuiSlider-valueLabel {
    font-size: 10px;
  }

  .MuiSlider-track {
    background-color: #bcd8e8;
  }
}

.gated-content-gradient {
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0), #fff 75%);
}

/* Hotfix Popper error in XGrid */
.muic-file-manager .MuiDataGrid-overlay {
  max-height: calc(100% - 32px);
}
